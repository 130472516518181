import { CITIES_ACTION_TYPES } from '../actions/citiesActions';

export default function cities(state = {}, action = {}) {
  switch (action.type) {
    case CITIES_ACTION_TYPES.CITIES_GET_BY_ID:
      const citiesList = {};
      citiesList[action.cityId] = { ...action.data, provinceId: action.data.provinces_provinceId };

      return { ...state, cities: citiesList };
    case CITIES_ACTION_TYPES.CITIES_GET_BY_PROVINCE_QUERY:
      const provinces = {};
      provinces[action.province] = !action.data ? []
        : action.data.map(({ cityId, city }) => ({ value: cityId, label: city }));

      return { ...state, provinces };
    case CITIES_ACTION_TYPES.CITIES_GET_BY_QUERY:
      /* eslint-disable camelcase */
      const citiesOptions = !action.data ? []
        // eslint-disable-next-line max-len
        : action.data.map(({ cityId, city, provinces_provinceId }) => ({ value: cityId, label: city, provinceId: provinces_provinceId }));
      /* eslint-enable camelcase */

      return { ...state, queryResult: citiesOptions };
    default:
      return state;
  }
}
