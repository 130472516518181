import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, reduxForm, FormSection } from 'redux-form';
import { Button, FormGroup } from 'react-bootstrap';
import _ from 'lodash';
import * as Sentry from '@sentry/browser';
import { getProvinceByValue } from '../../utils/provinces';
import { validation, digits } from '../../utils/forms/validation';
import ReduxFormControl from '../../components/forms/ReduxFormControl';
import rent from '../../data/estate/rent';
import * as citiesActions from '../../actions/citiesActions';
import searchTypeForms from './search';
import searchTypeValidates from './search/validates';

class SearchForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      advSearch: false,
    };

    this.loadCities = this.loadCities.bind(this);
  }

  loadCities(text, callback) {
    if (text.length < 3) return;

    this.props.getCitiesByQuery(text);

    setTimeout(() => {
      callback(this.props.cities);
    }, 1000);
  }

  renderSelectOption = (option, selected) => {
    const province = getProvinceByValue(option?.provinceId);
    const selectedValue = selected?.selectValue[0]?.value;

    return (
      <div>
        <span style={{ backgroundColor: selectedValue === option.value ? '700' : 'normal' }}>
          {option.label}
        </span>
        {province && province?.label && (
          <span style={{ fontSize: '12px', color: '#aaa', marginLeft: '7px' }}>
            {province?.label}
          </span>
        )}
      </div>
    );
  }

  render() {
    const { handleSubmit, type } = this.props;
    const SearchTypeForm = searchTypeForms[type];

    return (
      <form onSubmit={handleSubmit}>
        <FormSection name="common" className="search-form">
          <div className="col-md-2 col-sm-6 col-xs-12">
            <div className="row">
              <Field component={ReduxFormControl} name="rent" type="select"
                placeholder="Rodzaj" options={rent}
              />
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
            <div className="row">
              <Field component={ReduxFormControl} name="city" type="select"
                async placeholder="Miejscowość" loadOptions={this.loadCities}
                tooltip="Wyszukaj miejscowość, wybierz poprawne województwo"
                formatOptionLabel={this.renderSelectOption}
              />
            </div>
          </div>
          <div className="col-md-2 col-sm-6 col-xs-6">
            <div className="row">
              <Field component={ReduxFormControl} name="priceFrom" type="text"
                placeholder="Cena od"
              />
            </div>
          </div>
          <div className="col-md-2 col-sm-6 col-xs-6">
            <div className="row">
              <Field component={ReduxFormControl} name="priceTo" type="text"
                placeholder="Cena do"
              />
            </div>
          </div>

          <div className="col-md-2 col-sm-6 col-xs-12">
            <FormGroup controlId="search-submit">
              <Button bsStyle="primary" className="btn-grey pull-right submit" type="submit">
                Szukaj
              </Button>
            </FormGroup>
          </div>
        </FormSection>

        <Button className="advanced-search"
          onClick={() => this.setState(prevState => ({ advSearch: !prevState.advSearch }))}
        >
          {`${this.state.advSearch ? 'mniej' : 'więcej'} opcji `}
          <i className={`fa fa-angle-${this.state.advSearch ? 'up' : 'down'}`} />
        </Button>

        <SearchTypeForm {...this.state} />
      </form>
    );
  }
}

SearchForm.propTypes = {
  type: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  getCitiesByQuery: PropTypes.func.isRequired,
  cities: PropTypes.array,
  values: PropTypes.object,
};

const validate = (values, props) => {
  const propsObj = _.isArray(props) ? props[1] : props;
  const common = values.common || {};
  const errors = { common: {}, details: {} };

  errors.common.priceFrom = validation(common.priceFrom, [digits()]);
  errors.common.priceTo = validation(common.priceTo, [digits()]);

  const searchTypeValidate = searchTypeValidates[propsObj.type];
  // TODO: Remove once `TypeError: l.validate is not a function` is solved
  if (typeof searchTypeValidate !== 'function') {
    Sentry.setContext('Search', { props, form: searchTypeValidate });
  }
  searchTypeValidate(values, errors);

  return errors;
};

export default reduxForm({ form: 'search', validate })(
  connect(
    (state) => ({ cities: state.cities.queryResult, values: state.form.search.values || {} }),
    (dispatch) => bindActionCreators(citiesActions, dispatch),
  )(SearchForm),
);
