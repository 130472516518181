import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, reduxForm, FormSection } from 'redux-form';
import { Button, FormGroup } from 'react-bootstrap';
import _ from 'lodash';
import { validation, required, minLength, maxLength, digits } from '../../utils/forms/validation';
import ReduxFormControl from '../../components/forms/ReduxFormControl';
import market from '../../data/estate/market';
import provinces from '../../data/provinces';
import rent from '../../data/estate/rent';
import estateTypeForms from './estate';
import { getType } from '../../utils/estate/types';
import * as citiesActions from '../../actions/citiesActions';

const DEFAULT_COUNTER = 3000;

class EstateForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      countRemains: DEFAULT_COUNTER,
      emptyProvince: _.isEmpty((props.values.estate || {}).province),
    };
    this.textHandler = this.textHandler.bind(this);
    this.loadCities = this.loadCities.bind(this);
    this.chooseProvince = this.chooseProvince.bind(this);
  }

  componentDidUpdate() {
    if (this.props.values.estate && this.props.values.estate.description) {
      this.updateCounter(this.props.values.estate.description.length);
    }
  }

  textHandler(event) {
    const text = event.target.value;
    this.updateCounter(text.length);
  }

  updateCounter(length) {
    this.setState({ countRemains: DEFAULT_COUNTER - length });
  }

  chooseProvince() {
    this.setState({ emptyProvince: false });
    if (this.props.values.estate) {
      this.props.values.estate.city = null;
    }
  }

  loadCities(text, callback) {
    const province = (this.props.values.estate && this.props.values.estate.province) || null;
    if (!province || text.length < 3) return;

    this.props.getCitiesByProvinceQuery(province.value, text);

    setTimeout(() => {
      callback(this.props.provincesQuery[province?.value] || []);
    }, 1000);
  }

  render() {
    const { handleSubmit, type } = this.props;
    const EstateTypeForm = estateTypeForms[getType(type, true)];

    return (
      <form className="row" onSubmit={handleSubmit}>
        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
          <FormSection name="estate">
            <div>
              <Field component={ReduxFormControl} name="rent" type="select"
                placeholder="Rodzaj" useLabel required options={rent}
              />
            </div>
            <div>
              <Field component={ReduxFormControl} name="market" type="select"
                placeholder="Rynek" useLabel required options={market}
              />
            </div>
            <div>
              <Field component={ReduxFormControl} name="title" type="text"
                placeholder="Tytuł" useLabel required
              />
            </div>
            <div>
              <Field component={ReduxFormControl} name="address" type="text"
                placeholder="Adres" useLabel required
              />
            </div>
            <div>
              <Field component={ReduxFormControl} name="cityCode" type="text"
                placeholder="Kod pocztowy" useLabel required
              />
            </div>
            <div>
              <Field component={ReduxFormControl} name="province" type="select"
                placeholder="Województwo" useLabel required options={provinces}
                onChange={this.chooseProvince}
              />
            </div>
            <div>
              <Field component={ReduxFormControl} name="city" type="select"
                async placeholder="Miejscowość" loadOptions={this.loadCities}
                isDisabled={this.state.emptyProvince}
                useLabel required tooltip="Wybierz województwo, a następnie wyszukaj miejscowość"
              />
            </div>
            <div>
              <Field component={ReduxFormControl} name="district" type="text"
                placeholder="Dzielnica" useLabel
              />
            </div>
            <div>
              <Field component={ReduxFormControl} name="price" type="text"
                placeholder="Cena (PLN)" useLabel required
                tooltip="Podaj kwotę w PLN"
              />
            </div>
            <div>
              <Field component={ReduxFormControl} name="area" type="text"
                placeholder="Powierzchnia (m²)" useLabel required
                tooltip="Podaj powierzchnię w m²"
              />
            </div>
            <div>
              <Field component={ReduxFormControl} onChange={this.textHandler} placeholder="Opis"
                useLabel required
                componentClass="textarea" name="description" type="textarea"
              />
              <div className="col-xs-12 pull-right margin-bottom20">
                Pozostało:
                {' '}
                <span className="count-remains">{this.state.countRemains}</span>
                {' '}
                znaków
              </div>
            </div>
          </FormSection>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
          <EstateTypeForm />
        </div>

        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <FormGroup controlId="add-ad-1-submit">
            <Button bsStyle="success" className="submit pull-right" type="submit">
              Dalej
            </Button>
          </FormGroup>
        </div>
      </form>
    );
  }
}

EstateForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  type: PropTypes.string,
  getCitiesByProvinceQuery: PropTypes.func.isRequired,
  values: PropTypes.object,
  provincesQuery: PropTypes.object,
};

const validate = (values, props) => {
  const estate = values.estate || {};
  const errors = { estate: {}, estateType: {} };

  errors.estate.rent = validation(estate.rent, [required()]);
  errors.estate.market = validation(estate.market, [required()]);
  errors.estate.title = validation(estate.title, [required(), minLength(3), maxLength(255)]);
  errors.estate.address = validation(estate.address, [required(), minLength(3), maxLength(50)]);
  errors.estate.cityCode = validation(estate.cityCode, [required(), minLength(5), maxLength(6)]);
  errors.estate.district = validation(estate.district, [minLength(3), maxLength(45)]);
  errors.estate.province = validation(estate.province, [required()]);
  errors.estate.city = validation(estate.city, [required()]);
  errors.estate.price = validation(estate.price, [required(), digits()]);
  errors.estate.area = validation(estate.area, [required(), digits()]);
  errors.estate.description = validation(
    estate.description, [required(), minLength(60), maxLength(3000)],
  );

  const EstateTypeForm = estateTypeForms[getType(props.type, true)];
  EstateTypeForm.validate(values, errors);

  return errors;
};

export default reduxForm({ form: 'estate', validate })(
  connect(
    (state) => ({ provincesQuery: state.cities.provinces, values: state.form.estate.values || {} }),
    (dispatch) => bindActionCreators(citiesActions, dispatch),
  )(EstateForm),
);
