/* eslint react/prop-types: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FormControl, FormGroup, Glyphicon, OverlayTrigger, Tooltip } from 'react-bootstrap';
import AsyncSelect from 'react-select/async';
import Dropzone from 'react-dropzone';
import Select from '../Select';

const renderAsyncSelect = (input, meta, props) => (
  <AsyncSelect
    {...input} name={props.name}
    instanceId={`${meta.form}-city`}
    className="react-select-container" classNamePrefix="react-select"
    placeholder={props.placeholder}
    formatOptionLabel={props.formatOptionLabel}
    loadOptions={props.loadOptions} autoload={false}
    cache={false} isDisabled={props.isDisabled}
    loadingMessage={() => 'Pobieram dane...'} noOptionsMessage={() => 'Wpisz aby wyszukać...'}
    onChange={value => input.onChange(value)} onBlur={() => input.onBlur()}
  />
);

const renderFile = (input, props) => (
  <Dropzone className="dropzone-container" name={input.name}
    accept={props.accept} maxSize={8388608} multiple
    onDrop={(acceptedFiles, rejectedFiles) => {
      const parsed = acceptedFiles.map(
        file => Object.assign(file, { preview: URL.createObjectURL(file) }),
      );
      if (props.onUpload) props.onUpload(parsed, rejectedFiles);

      input.onChange({ accepted: acceptedFiles, rejected: rejectedFiles });
      input.onFocus(true);
    }}
  >
    {({ getRootProps, getInputProps }) => (
      <div {...getRootProps()} className="drag-drop">
        <input {...getInputProps()} />
        <i className="fa fa-cloud-upload" />
        <span className="drag-drop-text">Drag&amp;Drop</span>
      </div>
    )}
  </Dropzone>
);

const renderLabel = (id, input, props) => (
  <label htmlFor={id} className="form-label">
    {props.placeholder}
    {props.required && <span className="form-label-asterisk">*</span>}
    {props.tooltip && (
      <span className="form-tooltip">
        <OverlayTrigger placement="left" className="form-tooltip"
          overlay={<Tooltip id={`tooltip-${input.name}`}>{props.tooltip}</Tooltip>}
        >
          <Glyphicon glyph="info-sign" />
        </OverlayTrigger>
      </span>
    )}
  </label>
);

const renderSelect = (input, props) => (
  <Select
    {...input} name={props.name}
    options={props.options} value={input.value}
    placeholder={props.placeholder}
    onChange={value => input.onChange(value)} onBlur={() => input.onBlur()}
  />
);

function ReduxFormControl({ input, meta, ...props }) {
  const error = meta.visited && meta.error;
  const formControlProps = _.omit(props, ['async', 'useLabel']);
  const id = `${meta.form}-${input.name}`;
  const className = (props.type === 'checkbox') ? 'checkbox' : '';
  const controlType = !_.includes(['checkbox', 'file', 'select'], props.type);
  const checked = !meta.visited ? props.checked : input.checked;

  return (
    <FormGroup controlId={id} validationState={error ? 'error' : null} className={`col-xs-12 ${className}`}>
      {props.type === 'checkbox' && props.checked && (
        <input type="checkbox" id={id} {...input} checked={checked} />
      )}
      {props.type === 'checkbox' && !props.checked && (
        <input type="checkbox" id={id} {...input} />
      )}

      {props.useLabel && renderLabel(id, input, props)}
      {props.useLabel && props.required && (
        <span className="form-asterisk">*</span>
      )}

      {props.type === 'file' && renderFile(input, props)}

      {props.type === 'select' && !props.async && renderSelect(input, props)}

      {props.type === 'select' && props.async && renderAsyncSelect(input, meta, props)}

      {controlType && (
        <FormControl {...formControlProps} {...input} />
      )}

      {props.useLabel && error && (
        <ul><li>{error}</li></ul>
      )}
    </FormGroup>
  );
}

ReduxFormControl.propTypes = {
  async: PropTypes.bool,
  input: PropTypes.shape({
    name: PropTypes.string,
  }),
  label: PropTypes.string,
  meta: PropTypes.shape({
    form: PropTypes.string,
    visited: PropTypes.bool,
  }),
  options: PropTypes.array,
  required: PropTypes.bool,
  tooltip: PropTypes.string,
  type: PropTypes.string,
  useLabel: PropTypes.bool,
};

export default ReduxFormControl;
